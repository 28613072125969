.Credit{
	margin-right: 5px;
}

.Credit:hover{
	transform: scale(.9);
}

.loading{
	display: none;
}
