.Footer{
	flex-shrink: 0;
	padding: 3px;
	background: rgba(33, 33, 33, .45);
	color: #eee;
	text-align: center;
}

.Footer a {
	color: inherit;
}
