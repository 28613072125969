.Autocomplete {
	font-family: 'Bangers', cursive;
	margin-bottom: 10px;
	color: #212121;
}

.Autocomplete div{
	width: 100%;
}

.Autocomplete input{
	width: 100%;
	padding: 10px;
	font-weight: bold;
}

.Autocomplete li{
	font-size: 1.2em;
	letter-spacing: 2px;
}
