.autocompleteWrapper{
	margin: auto;
	width: 20%;
	min-width: 200px;
}

.PeopleComparison{
	box-sizing: border-box;
	padding: 36px 0;
	height: 100%;
	flex: 1 0 auto;
}

.People{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
}

.commonCreditsWrapper{
	margin-bottom: 5px;
	padding: 3px;
	border: 1px solid;

	background: rgba(33, 33, 33, .85);
}

.helpText{
	font-family: 'Bangers', cursive;
	text-align: center;
	font-size: 3em;
	text-shadow: 3px 3px #0e2a0e;
}

.highlighted{
	text-decoration: underline;
}

@media screen and (max-width : 760px) {
	.People{
		flex-direction: column-reverse;
		margin: auto;
		width: 300px;
	}
}
